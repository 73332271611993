import React from "react"
import styles from "./scroll.module.scss"

interface Props {
  theme: "light" | "dark"
}

interface State {
  visibility: string
}

export default class Scroll extends React.Component<Props> {
  visibilityClass: string
  state: State = {
    visibility: "",
  }
  constructor(props) {
    super(props)
    this.visibilityClass = ""
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        this.visibilityClass = styles.hidden
      } else {
        this.visibilityClass = styles.visible
      }
      this.setState({
        visibility: this.visibilityClass,
      })
    })
  }

  render() {
    console.log("scroll", this.visibilityClass)
    return (
      <span
        className={`${styles.wrapper} ${this.state.visibility} ${
          styles[this.props.theme]
        }`}
      >
        <hr />
        <span className="label1">Scroll</span>
      </span>
    )
  }
}
